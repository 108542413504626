import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

import '../text-input/text-input.vue'

Vue.component('cart-gift-message', {
  data () {
    return {
      open: false,
      message: '',
      disabled: false
    }
  },
  created () {
    if (this.giftMessage) {
      this.open = this.giftMessage
    }
  },
  computed: {
    ...mapGetters(['giftMessage'])
  },
  methods: {
    ...mapActions(['updateGiftMessage']),
    onMessageUpdate (event) {
      this.disabled = false
      this.message = event.value

      if (this.message === this.giftMessage) {
        this.disabled = true
      }
    },
    handleSubmit () {
      this.updateGiftMessage(this.message)
      const giftMessageComponent = (this.$children || []).find(item => item.name === 'gift_message')
      if (giftMessageComponent) {
        giftMessageComponent.$refs.input.focus()
      }
      this.disabled = true
    }
  }
})
